<template>
    <div class="wrap">
      <div class="iconBox">
        <img src="../../assets/dinosaur/image/help.png" alt="" class="rule" @click="showRule = true">
        <div class="progress-bar" v-show="!isWaiting">
          <div class="progress-bar-title">
            <div class="progress-bar-countdown">
               <img src="../../assets/dinosaur/image/countdownIcon.png" alt="" class="waiting-time-img"></img>
               <span class="comeText">boss离开</span>
               <span class="countdown"><van-count-down :time="gameTime"/></span>
            </div>
            <div class="progress-bar-title-text">侏罗纪公园</div>
          </div>
          <div class="progress-bar-content">
            <div class="bar-fg"></div>
            <div class="bar-text">{{ loseBlood }}/{{bossHP}}</div>
          </div>
        </div>
        <img  src="../../assets/dinosaur/image/close.png"  alt=""  class="closeGame" @click="close"/>
      </div>
      <div class="waiting-state" v-if="isWaiting">
        <img src="../../assets/dinosaur/image/title.png" alt="" class="title-img">
        <div class="waiting-time">
          <img src="../../assets/dinosaur/image/countdownIcon.png" alt="" class="waiting-time-img"></img>
          <span class="comeText">boss到来</span>
          <span class="countdown">
            <van-count-down :time="gameTime" format="HH:mm:ss" />
          </span>
          
        </div>
      </div>
      <div class="game-state" v-else>
        <img :src="dinosaurism" alt="" class="dinosaur-img">
        <div class="game-state-weapon">
          <img src="../../assets/dinosaur/image/theKnife.png" alt="" class="theKnifeIcon" v-if="iscrabstick">
          <img src="../../assets/dinosaur/image/dao.gif" alt="" class="theKnifeIcon gifIcon" v-else>
          <img src="../../assets/dinosaur/image/woodenStick.png" alt="" class="woodenStickIcon" v-if="isbroadsword">
          <img src="../../assets/dinosaur/image/gun.gif" alt="" class="woodenStickIcon gifIcon" v-else>
          <img src="../../assets/dinosaur/image/pistol.png" alt="" class="pistolIcon">
          <img src="../../assets/dinosaur/image/qiang.gif" alt="" class="qiangIcon" v-if="ispistol">
        </div>
      </div>

      <!-- 功能按钮 -->
       <div class="btns">
        <div class="crabstick-btn btn-play">
            <span>{{priceList.mu_gun}}橙币</span>
            <div @click="handleAttack('crabstick')" :class="{isCrabstick : num== 'mu_gun'}">木棍攻击</div>
        </div>
        <div class="broadsword-btn btn-play">
            <span>{{priceList.da_dao}}橙币</span>
            <div @click="handleAttack('broadsword')" :class="{isCrabstick : num== 'da_dao'}">大刀攻击</div>
        </div>
        <div class="pistol-btn btn-play">
            <span>{{priceList.shou_qiang}}橙币</span>
            <div @click="handleAttack('pistol')" :class="{isCrabstick : num== 'shou_qiang'}">手枪攻击</div>
        </div>
       </div>
       <div class="balance-num">余额: {{gold}}</div>

  
      <audio id="audio" ref="audio" autoplay >
        <source :src="videoUrl" type="audio/wav" />
      </audio>
      <rule
        :content="dataObj['gold']['remark']"
        v-show="showRule" 
        @close="showRule = false"
      />
      <prize v-if="showPrize" :prize="prize" @close="reset" />
    </div>
  </template>
  
  <script>
  import "@/assets/dinosaur/css/index.css";
  import rule from "@/components/rule";
  import prize from "@/components/prize";
  import { Toast } from "vant";

   import goldCoin from '@/assets/dinosaur/image/goldCoin.png'
  import normalDinosaur from '@/assets/dinosaur/image/normal.gif';
  import InjuryDinosaur from '@/assets/dinosaur/image/Injury.gif';

  import bgVideo from '@/assets/dinosaur/video/bg.mp3';
  import mugunVideo from '@/assets/dinosaur/video/mugun.mp3';
  import daoVideo from '@/assets/dinosaur/video/dao.mp3';
  import qiangVideo from '@/assets/dinosaur/video/qiang.mp3';
  
  export default {
    components: {
      prize,
      rule,
    },
    data() {
      return {
        isWaiting:true,
        dinosaurism: normalDinosaur,    //游戏状态
        gameTime: 0 * 0 * 0 * 0,     //游戏时间
        attackType:'crabstick',
        videoUrl: bgVideo,
        iscrabstick:true,    //是否使用木棍
        isbroadsword: true,    //是否使用大刀
        ispistol: false,    //是否使用手枪

        bossHP: 0, //boss血量
        loseBlood:0,    //扣血
        loseBloodNum: 0,    //掉落血量
        goldDrop: 200,    //掉金币
        gameId:'',
        showRule: false,        
        showPrize: false,
        gold: 0,                //我的金币数量
        prize: null,
        num: "",
        playNum:'',       //需要的金币数量
        dataObj:{
          dinosaurData:{},
          gold:{}
        },
        videoTime:'',
        priceList :{
          mu_gun:'',
          da_dao:'',
          shou_qiang:''
        }
      };
    },
    mounted() {
      this.getInfo();
      let audioDom = document.querySelector("#audio");
      audioDom.onended = function() {
      //播放完毕，重新循环播放
      audioDom.load();
      audioDom.play();
      this.videoTime = setTimeout(() => {
      this.$refs.audio && this.$refs.audio.play();
    },500);
    };

    },
    watch: {
      "globalData.XPLATFORM": {
        handler(newVal) {
          newVal && this.getInfo();
        },
      },
    },

    sockets: {
      // joinDinosaurGame      killBoss
    disconnect() {
      console.log("Socket 断开");
    },
    error() { 
      console.log("Socket 连接失败");
    },
    connect() {
      console.log("Socket 连接成功");
      this.$socket.emit("joinDinosaurGame", { room_no: this.globalData.roomId });
    },
    dinosaurgameUser(data) {
      this.goldDrop = data.diao_luo_gold;
      this.loseBloodNum = data.diao_luo_xue_liang
      this.getInfo()
      if(this.num){
      this.dinosaurism = InjuryDinosaur
      this.randomDrop()
      setTimeout(()=>{
        this.dinosaurism = normalDinosaur
        this.iscrabstick = true 
        this.isbroadsword = true 
        this.ispistol = false
      },2000)
      }
    },
    dinosaurGameMassage(data){
      console.log("dinosaurGameMassage", data);
      this.gameId = data.game_id;
      this.isWaiting = data.game_status == '0';
      this.bossHP = data.boss_all_xue
      this.loseBlood = data.boss_sheng_xue;
      document.getElementsByClassName("bar-fg")[0].style.width =
      data.boss_sheng_xue / data.boss_all_xue * 100 + "%";
      if(data.game_status == '0'){
        this.gameTime = data.game_time_xiu * 1000;
        if(document.querySelector('.dropGold')){
          document.querySelector('.dropGold').remove()
          document.querySelector('.loseBloodNum').remove()
        }
      } else {
        this.gameTime = data.game_time_left * 1000;
      }
    },
    },

    methods: {

      // 金币血量掉落位置
      randomDrop(){
      var gameBox = document.querySelector('.game-state')
      let imgBox = document.createElement('img')
      imgBox.src = goldCoin
      imgBox.className = 'dropGold'
      gameBox.appendChild(imgBox)
      imgBox.style.top = this.getRandomNumber(2,5)+'rem'
      imgBox.style.left = this.getRandomNumber(18,80)+'%'
      imgBox.style.opacity = 1
      imgBox.addEventListener('animationend',()=>{
          imgBox.remove()
      })

      let textBox = document.createElement('div')
      textBox.className = 'loseBloodNum'
      textBox.innerText = ` - ${this.loseBloodNum}`
      gameBox.appendChild(textBox)
      textBox.style.top = this.getRandomNumber(2,5)+'rem'
      textBox.style.left = this.getRandomNumber(20,60)+'%'
      textBox.style.opacity = 1
      textBox.addEventListener('animationend',()=>{
        textBox.remove()
      })
      },

      // 随机生成10-90
      getRandomNumber(min, max) {
         return Math.floor(Math.random() * (max - min + 1)) + min;
      },

      // 游戏情况
      getInfo() {
        if (this.globalData.XPLATFORM) {
          this.$http
            .get("lottery-game/info", {
              params: { code: "mu_gun" },
              headers: {
                "X-PLATFORM": this.globalData.XPLATFORM,
              },
            })
            .then((res) => {
              this.dataObj.dinosaurData = res.data;
              this.gold = res.data["gold"];     //金币
              this.dataObj.gold.remark = res.data.remark;
              this.priceList.mu_gun = res.data["price"];
            });

            this.$http
            .get("lottery-game/info", {
              params: { code: "da_dao" },
              headers: {
                "X-PLATFORM": this.globalData.XPLATFORM,
              },
            })
            .then((res) => {
              this.dataObj.dinosaurData = res.data;
              this.gold = res.data["gold"];     //金币
              this.dataObj.gold.remark = res.data.remark;
              this.priceList.da_dao = res.data["price"];
            });

            this.$http
            .get("lottery-game/info", {
              params: { code: "shou_qiang" },
              headers: {
                "X-PLATFORM": this.globalData.XPLATFORM,
              },
            })
            .then((res) => {
              this.dataObj.dinosaurData = res.data;
              this.gold = res.data["gold"];     //金币
              this.dataObj.gold.remark = res.data.remark;
              this.priceList.shou_qiang = res.data["price"];
            });
        }
      },

      // 攻击
      handleAttack(type){
        if(this.isWaiting){
          return Toast({
          message: '等待boss到来',
          position: 'bottom',
        });
        } else {
          if (type == "crabstick") {
            this.num = 'mu_gun';
            this.playNum='500'
          } else if (type == "broadsword") {
            this.num = "da_dao";
            this.playNum='1000'
          } else{
            this.num = "shou_qiang";
            this.playNum='1500'
          }
          // 判断余额是否足够
          if (parseInt(this.gold) < parseInt(this.playNum)) {
            return Toast.fail({ message: "余额不足", position: "bottom" });
          } else {
            this.num == 'mu_gun'? this.playMusic(mugunVideo, 1) : this.num == 'da_dao'? this.playMusic(daoVideo, 1) : this.playMusic(qiangVideo, 1)
            setTimeout(() => {
              this.playMusic(bgVideo, 0.8)
            }, 1000)
            type == 'crabstick'? this.isbroadsword = false : type == 'broadsword' ? this.iscrabstick = false : this.ispistol = true
            this.$socket.emit("killBoss", {game_id: this.gameId ,rod_type:this.num, room_no: this.globalData.roomId});
          }
        }
      },

      // 音效
      playMusic(musicType, speed){
      this.videoUrl = musicType
      this.$refs.audio.load(); // 加载音频文件
      this.$refs.audio.play(); // 播放音频文件
      this.$refs.audio.playbackRate = speed; // 播放音频文件的倍速
     },
     reset() {
      this.$refs.audio.pause();
     },
    },

    beforeDestroy() {
     this.$socket.disconnect();
     clearTimeout(this.videoTime);
     this.$refs.audio.pause();
    }
  };
  </script>
  
  <style scoped></style>
  